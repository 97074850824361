import AccordionSection from "components/common/AccordionSection";
import DeviceCards from "components/common/DeviceCards";
import Features from "components/common/Features";
import TrialBanner2 from "components/common/footer-banners/TrialBanner2";
import HeroLayout1 from "components/common/hero/HeroLayout1";
import RhombusBlade from "components/common/RhombusBlade";
import { graphql, StaticQuery } from "gatsby";
import React from "react";

import SensorOverviewIndustrySection from "./overview/SensorOverviewIndustrySection";

export default function SensorOverviewPage() {
  const renderContent = data => {
    const headerSection = {
      preTitle: "Connected sensors",
      title: "Connected sensors",
      description:
        "Obtain real-time, actionable insights across your environment with powerful sensors paired with high-resolution video.",
      image: data.headerImage,
      button1: {
        text: "Start Trial",
        path: "/free-trial/",
      },
    };

    const cardSection = {
      title: "Protect What Matters Most",
      cards: [
        {
          image: data.button,
          name: "Smart Button",
          description:
            "Get one-touch emergency responses with this programmable button.",
          link: "/sensors/b10/",
          flex: 3,
        },
        {
          image: data.audio,
          name: "Audio Gateway",
          description:
            "Intelligent audio analytics with complete sensor connectivity.",
          link: "/sensors/a100/",
          flex: 3,
        },
        {
          name: "Environmental Monitoring",
          description:
            "Monitor air quality, vape, and temperature in real-time.",
          link: "/sensors/environmental-monitoring/",
          image: data.environmental,
          flex: 3,
        },
        {
          image: data.entry,
          name: "Entry Monitoring",
          description: "Secure any entry point throughout your environment.",
          link: "/sensors/d20/",
          flex: 3,
        },
        {
          name: "Motion Sensing",
          description:
            "Detect motion or space occupancy while protecting privacy.",
          link: "/sensors/m1/",
          image: data.motion,
          flex: 3,
        },
      ],
    };

    const accordionSection = {
      title: "Elevate Your Physical Security with Connected Sensors",
      items: [
        {
          title: "Sound the Alarm",
          p:
            "Accelerate emergency response with the single push of a button. Instantaneously alert dispatchers of an emergency and trigger a loud siren to draw attention and deter unwanted guests.",
          img: data.one,
          alt: "Rhombus button being pressed when armed man enters building",
        },
        {
          title: "Monitor Environments",
          p:
            "Protect employees, equipment, and assets from hazardous environmental changes. Receive instant notifications for temperature fluctuations or when smoke, vape, or toxic air quality is detected.",
          img: data.two,
          alt: "Rhombus sensor in refrigerator",
        },
        {
          title: "Expand Visibility",
          p:
            "Increase safety and security in spaces where cameras are not needed or allowed. Track motion and entry events for unusual activity to protect against unauthorized access, theft, and vandalism.",
          img: data.three,
          alt: "Rhombus door sensors on door",
        },
      ],
    };

    const features = [
      {
        icon: data.icon1,
        iconAlt: "Sensor Network",
        title: "Sensor Network",
        p:
          "Customize your physical security and acquire new insights beyond just capturing video.",
      },
      {
        icon: data.icon2,
        iconAlt: "Secure & Reliable",
        title: "Secure & Reliable",
        p:
          "All communication is end-to-end encrypted for uncompromising reliability and cybersecurity.",
      },
      {
        icon: data.icon3,
        iconAlt: "Powerful Data",
        title: "Powerful Data",
        p:
          "Receive real-time alerts and visualize sensor data with video evidence to streamline operations.",
      },
      {
        icon: data.icon4,
        iconAlt: "Automatic Updates",
        title: "Automatic Updates",
        p:
          "Always up to date and secure — receive new features and product enhancements at no cost.",
      },
    ];

    return (
      <>
        <HeroLayout1 data={headerSection} bottom reverse />
        <DeviceCards data={cardSection} />
        <AccordionSection data={accordionSection} color="var(--nuetral-100)" />
        <SensorOverviewIndustrySection />
        <Features
          color="var(--nuetral-100)"
          data={features}
          title="Sensor Features"
        />
        <TrialBanner2 title="Try Rhombus Sensors for Free!" />
      </>
    );
  };
  const GET_IMAGES = graphql`
    query {
      headerImage: file(
        relativePath: {
          eq: "components/sensors/overview/img/sensor-podium-3x-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      button: file(
        relativePath: {
          eq: "components/sensors/overview/img/button-500-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      audio: file(
        relativePath: {
          eq: "components/sensors/overview/img/audio-gateway-500-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      entry: file(
        relativePath: {
          eq: "components/sensors/overview/img/entry-monitoring-500-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      environmental: file(
        relativePath: { eq: "components/sensors/img/e50-card-fade-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      motion: file(
        relativePath: {
          eq: "components/sensors/overview/img/motion-sensing-500-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      web: file(
        relativePath: {
          eq: "components/sensors/overview/img/sensor-trial-banner.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      mobile: file(
        relativePath: {
          eq: "components/sensors/overview/img/sensor-trial-banner-mobile.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      one: file(
        relativePath: { eq: "components/sensors/overview/img/acc-1-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: NONE)
        }
      }
      two: file(
        relativePath: { eq: "components/sensors/overview/img/acc-2-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: NONE)
        }
      }
      three: file(
        relativePath: { eq: "components/sensors/overview/img/acc-3-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: NONE)
        }
      }
      icon1: file(relativePath: { eq: "components/common/icons/network.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      icon2: file(
        relativePath: { eq: "components/industries/img/icons/security.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      icon3: file(
        relativePath: { eq: "components/industries/img/icons/efficient.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      icon4: file(
        relativePath: { eq: "components/industries/img/icons/updates.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
    }
  `;

  return <StaticQuery query={GET_IMAGES} render={renderContent} />;
}
